import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store";
import { NavigationPagePaths } from "./navigationPagePath";
import CognitoService from "../services/cognitoService";
import { userActions } from "../features/user/userSlice";

export default function ProtectedRoute({
  children,
}: {
  children: JSX.Element;
}) {
  const { auth } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const location = useLocation();
  useEffect(() => {
    CognitoService.isSessionValid()
      .then((s) => {
        if (s === null) {
          dispatch(userActions.signOut("Session Expired"));
        }
      })
      .catch(() => dispatch(userActions.signOut("Session Expired")));
    const checkMark = setInterval(() => {
      console.log("checking");
      CognitoService.isSessionValid()
        .then((s) => {
          if (s === null) {
            dispatch(userActions.signOut("Session Expired"));
          }
        })
        .catch(() => dispatch(userActions.signOut("Session Expired")));
    }, 300_000);
    return () => {
      clearInterval(checkMark);
    };
  }, []);
  if (!auth) {
    // not logged in so redirect to login page with the return url
    return (
      <Navigate to={NavigationPagePaths.LOGIN} state={{ from: location }} />
    );
  }

  // authorized so return child components
  return children;
}
