import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { CognitoIdentityServiceProvider, config } from "aws-sdk";
import {
  AdminUpdateUserAttributesRequest,
  AttributeType,
} from "aws-sdk/clients/cognitoidentityserviceprovider";
import { CognitoIdentityCredentials } from "aws-sdk/lib/core";
import { AWS_CONFIG, AWS_CREDENTIALS } from "../constants/aws_config";
import { IUser } from "../interfaces/user";
import { makeid } from "../utils";
import { LocalStorage, LocalStorageKeys } from "../utils/localStorage";
import { IAuth } from "../interfaces/auth";
import { data } from "jquery";
/**
 * @summary AWS cognito service Factory funtion
 * @returns {Object} object of cognito service methods
 */
function cognitoService() {
  /**
   * Configure AWS cognito metadata
   */
  let cognitoUser: CognitoUser | null = null;
  const poolData = {
    UserPoolId: AWS_CONFIG.userPoolId,
    ClientId: AWS_CONFIG.clientId,
    region: AWS_CONFIG.region,
  };
  config.region = poolData.region;
  config.credentials = new CognitoIdentityCredentials({
    IdentityPoolId: AWS_CONFIG.identityPoolId,
  });
  config.update({
    accessKeyId: AWS_CREDENTIALS.accessKeyId,
    secretAccessKey: AWS_CREDENTIALS.secretAccessKey,
  });
  const userPool = new CognitoUserPool(poolData);
  const cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider({
    ...AWS_CREDENTIALS,
    region: AWS_CONFIG.region,
  });

  function initConfigCredentials(jwtToken: string) {
    const logins = {
      [AWS_CONFIG.cognitoEndpoint + "/" + AWS_CONFIG.userPoolId]: jwtToken,
    };
    config.credentials = new CognitoIdentityCredentials({
      IdentityPoolId: AWS_CONFIG.identityPoolId,
      Logins: logins,
    });
  }

  /**
   * @summary Register a User on Aws Cognito
   * @param data User payload
   * @returns promise to return a CognitoUser
   */
  function registerUser(data: IUser) {
    const attributeList: CognitoUserAttribute[] = [];

    const dataEmail = {
      Name: "email",
      Value: data.email,
    };
    const dataAdmin = {
      Name: "custom:Camber",
      Value: data.admin ? "1" : "",
    };
    const dataGivenName = {
      Name: "given_name",
      Value: data.first,
    };
    const dataFamilyName = {
      Name: "family_name",
      Value: data.last,
    };
    const dataClientName = {
      Name: "custom:client_name",
      Value: data.clientName ? data.clientName : "",
    };
    const dataBranch = {
      Name: "custom:branches",
      Value: data.branches ? data.branches : "",
    };
    const dataProfile = {
      Name: "custom:ResponderProfile",
      Value: data.responderprofile ? "1" : "0",
    };
    const dataInMarketMonitoring = {
      Name: "custom:InMarketMonitoring",
      Value: data.inmarketmonitoring ? "1" : "0",
    };
    const dataRemarketing = {
      Name: "custom:ReMarketing",
      Value: data.remarketing ? "1" : "0",
    };
    const dataPassword = {
      Name: "custom:password",
      Value: makeid(8),
    };
    const attributeEmail = new CognitoUserAttribute(dataEmail);
    const attributeGivenName = new CognitoUserAttribute(dataGivenName);
    const attributeFamilyName = new CognitoUserAttribute(dataFamilyName);
    const attributeAdmin = new CognitoUserAttribute(dataAdmin);
    const attributeClientName = new CognitoUserAttribute(dataClientName);
    const attributeBranch = new CognitoUserAttribute(dataBranch);
    const attributeProfile = new CognitoUserAttribute(dataProfile);
    const attributeInMarketMonitoring = new CognitoUserAttribute(
      dataInMarketMonitoring
    );
    const attributeRemarketing = new CognitoUserAttribute(dataRemarketing);
    const attributePassword = new CognitoUserAttribute(dataPassword);

    attributeList.push(
      attributeEmail,
      attributeGivenName,
      attributeFamilyName,
      attributeAdmin,
      attributeClientName,
      attributeBranch,
      attributeProfile,
      attributeInMarketMonitoring,
      attributeRemarketing,
      attributePassword
    );
    return new Promise<CognitoUser | null>((resolve, reject) => {
      userPool.signUp(
        data.email,
        dataPassword.Value,
        attributeList,
        [],
        function (err, result) {
          if (err) {
            return reject(err);
          }
          // cognitoUser = result?.user || null; //Commented for issue fixing(after adding 2 users app gets logout)
          return resolve(cognitoUser);
        }
      );
    });
  }

  /**
   * @summary SignIn method for AWS cognito
   * @param Username Username or Email
   * @param Password Password
   * @returns {Promise} Promise of user signin
   */
  function signIn(Username: string, Password: string) {
    return new Promise<CognitoUserSession>((resolve, reject) => {
      const authDetails = new AuthenticationDetails({ Username, Password });
      cognitoUser = new CognitoUser({
        Username,
        Pool: userPool,
      });
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (result) => {
          initConfigCredentials(result.getIdToken().getJwtToken());
          return resolve(result);
        },

        newPasswordRequired(_userAttributes, requiredAttributes) {
          const attributes: Record<string, string | null> = {};
          requiredAttributes.map(function (x: string) {
            attributes[x] = prompt("Enter " + x + " :", "");
          });
          const newPassword =
            prompt(
              "Enter new password (password must contain minimum 8 characters, 1 uppercase letter, and 1 number) ",
              ""
            ) || "";
          cognitoUser?.completeNewPasswordChallenge(
            newPassword,
            attributes,
            this
          );
        },
        onFailure: (error: Error) => {
          return reject(error);
        },
      });
    });
  }

  /**
   * @summary Change password method for AWS cognito
   * @param oldPassword Old password of current auth
   * @param newPassword New password of current auth
   * @returns {Promise} return promise of string `SUCCESS`
   */
  function changePassword(oldPassword: string, newPassword: string) {
    return new Promise<string>((resolve, reject) => {
      if (cognitoUser === null) {
        return reject(new Error("Authentication not found"));
      }
      cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
        if (err) {
          return reject(err);
        }
        return resolve(result || "Success");
      });
    });
  }

  /**
   * @summary Confirm password using verification code of AWS cognito
   * @param username Username or Email of cognito user
   * @param verificationCode 6-digit verificationCode
   * @param newPassword New password of user
   * @returns {Promise} return promise of string if success
   */
  function confirmPassword(
    username: string,
    verificationCode: string,
    newPassword: string
  ) {
    return new Promise<string>((resolve, reject) => {
      const userData = { Username: username, Pool: userPool };
      cognitoUser = new CognitoUser(userData);
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: function (success) {
          return resolve(success);
        },
        onFailure: function (err) {
          return reject(err);
        },
      });
    });
  }

  /**
   * @summary SignOut method for AWS cognito
   * @returns {Promise} promise of boolean.Return `true` if SignOut will successful
   */
  function signOut() {
    return new Promise<boolean>((resolve, reject) => {
      if (cognitoUser === null) {
        return reject(new Error("Authentication not found"));
      }
      cognitoUser.signOut(() => resolve(true));
    });
  }

  /**
   * @summary Forgot password service of AWS cognito
   * @param username Username or Email of cognito user
   * @returns {Promise} return promise of string if success
   */
  function forgotPassword(username: string) {
    return new Promise<string | boolean>((resolve, reject) => {
      const userData = { Username: username, Pool: userPool };
      cognitoUser = new CognitoUser(userData);
      cognitoUser.forgotPassword({
        onSuccess: function (result: string) {
          return resolve(result);
        },
        onFailure: function (err) {
          return reject(err);
        },
        inputVerificationCode: function () {
          return resolve(true);
        },
      });
    });
  }

  /**
   * @summary Store `CognitoUser` auth details at localStorage.
   * @param user SignedIn `CognitoUser`
   */
  function setUserObject(
    ...[user, UserName]:
      | [user: CognitoUser]
      | [user: CognitoUserSession, UserName: string]
  ) {
    if (!user) return;
    if ("getSignInUserSession" in user) {
      const token = user.getSignInUserSession()?.getAccessToken().getJwtToken();
      const idToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
      LocalStorage.set(LocalStorageKeys.AUTH, {
        token,
        idToken,
        loggedIn: true,
        userName: user.getUsername(),
      });
    } else {
      const token = user.getAccessToken().getJwtToken();
      const idToken = user.getIdToken().getJwtToken();
      LocalStorage.set(LocalStorageKeys.AUTH, {
        token,
        idToken,
        loggedIn: true,
        userName: UserName,
      });
    }
  }

  /**
   * @summary Get `UserAttributes` of current `CognitoUser`
   * @returns {Promise} promise of `CognitoUserAttribute[]`
   */
  function getUserAttributes() {
    return new Promise<CognitoUserAttribute[]>((resolve, reject) => {
      if (cognitoUser === null) {
        return resolve([]);
      }
      cognitoUser.getUserAttributes(function (err, result) {
        if (err) {
          return reject(err);
        }
        resolve(result || []);
      });
    });
  }

  /**
   * @summary Update `UserAttributes` of current `CognitoUser`
   * @param user Record of Attributes of `CognitoUser`
   * @returns {Promise} promise of `CognitoIdentityServiceProvider.AdminUpdateUserAttributesResponse`
   */
  function updateUserAttributes(user: {
    Username: string;
    Attributes: Record<string, string | boolean>;
  }): Promise<CognitoIdentityServiceProvider.AdminUpdateUserAttributesResponse>;
  function updateUserAttributes(user: {
    Username: string;
    Attributes: CognitoIdentityServiceProvider.AttributeListType;
  }): Promise<CognitoIdentityServiceProvider.AdminUpdateUserAttributesResponse>;
  function updateUserAttributes(user: {
    Username: string;
    Attributes:
      | Record<string, string | boolean>
      | CognitoIdentityServiceProvider.AttributeListType;
  }) {
    const { Username, Attributes } = user;
    let params: AdminUpdateUserAttributesRequest;
    if (Attributes instanceof Array) {
      params = {
        UserAttributes: Attributes,
        Username,
        UserPoolId: AWS_CONFIG.userPoolId,
      };
    } else {
      params = {
        UserAttributes: [
          {
            Name: "custom:client_name",
            Value: Attributes["custom:client_name"]
              ? (Attributes["custom:client_name"] as string)
              : "",
          },
          {
            Name: "custom:branches",
            Value: Attributes["custom:branches"]
              ? (Attributes["custom:branches"] as string)
              : "",
          },
          {
            Name: "custom:Camber",
            Value: Attributes["custom:Camber"] == true ? "1" : "0",
          },
          {
            Name: "custom:ReMarketing",
            Value: Attributes["custom:ReMarketing"] == true ? "1" : "0",
          },
          {
            Name: "custom:ResponderProfile",
            Value: Attributes["custom:ResponderProfile"] == true ? "1" : "0",
          },
          {
            Name: "custom:InMarketMonitoring",
            Value: Attributes["custom:InMarketMonitoring"] == true ? "1" : "0",
          },
        ],
        UserPoolId: AWS_CONFIG.userPoolId,
        Username: user.Username,
      };
    }

    return new Promise<CognitoIdentityServiceProvider.AdminUpdateUserAttributesResponse>(
      (resolve, reject) => {
        cognitoIdentityServiceProvider.adminUpdateUserAttributes(
          params,
          function (err, data) {
            if (err) {
              return reject(err);
            }
            return resolve(data);
          }
        );
      }
    );
  }

  /**
   * @summary Delete a particular `CognitoUser` on AWS
   * @param Username Username or Email of CognitoUser
   * @returns {Promise} return promise of string `Success`
   */
  function deleteUser(Username: string) {
    const params = {
      UserPoolId: AWS_CONFIG.userPoolId /* required */,
      Username: Username /* required */,
    };
    return new Promise<string>((resolve, reject) => {
      cognitoIdentityServiceProvider.adminDeleteUser(params, function (err) {
        if (err) {
          // console.log(err, err.stack);
          reject(err);
          return;
        }
        resolve("Success"); // successful response
      });
    });
  }

  /**
   * @summary List of `CognitoUsers` on AWS
   * @returns {Promise} return promise of `CognitoIdentityServiceProvider.ListUsersResponse`
   */
  function listUsers(PaginationToken?: string) {
    return new Promise<CognitoIdentityServiceProvider.ListUsersResponse>(
      (resolve, reject) => {
        cognitoIdentityServiceProvider.listUsers(
          {
            UserPoolId: AWS_CONFIG.userPoolId,
            ...(PaginationToken ? { PaginationToken } : {}),
          },
          function (err, data) {
            if (err) {
              return reject(err);
            } else {
              return resolve(data);
            }
          }
        );
      }
    );
  }
  /**
   * Convert `CognitoUserAttribute[]` to JSON record
   * @param results array of CognitoUserAttribute
   * @returns {Record} object
   */
  function processUserAttributes(results: AttributeType[]) {
    const userAttributes: { [key: string]: string } = {};
    results.map(function (result) {
      userAttributes[result.Name] = result.Value || "";
    });
    return userAttributes;
  }

  /**
   * @summary Get `CognitoUserSession` of AWS cognito auth
   * @returns {Promise} promise of CognitoUserSession | null
   */
  function getSession() {
    return new Promise<CognitoUserSession | null>((resolve, reject) => {
      if (cognitoUser === null) {
        return reject(new Error("Authentication not found"));
      }
      cognitoUser.getSession(
        (err: Error | null, session: CognitoUserSession | null) => {
          if (err) {
            return reject(err);
          }
          return getUserAttributes()
            .then(function (x) {
              processUserAttributes(x);
              if (cognitoUser) setUserObject(cognitoUser);
              return resolve(session);
            })
            .catch((e) => reject(e));
        }
      );
    });
  }

  /**
   * @summary check is Session available of AWS cognito auth
   * @returns {Promise} promise of CognitoUserSession | null
   */
  function isSessionValid() {
    return new Promise<CognitoUserSession | null>((resolve, reject) => {
      if (cognitoUser === null) {
        // return reject(new Error("Authentication not found"));
        return;
      }
      cognitoUser.getSession(
        (err: Error | null, session: CognitoUserSession | null) => {
          if (err) {
            return reject(err);
          }
          return resolve(session);
        }
      );
    });
  }

  const localAuth = LocalStorage.get<IAuth>(LocalStorageKeys.AUTH);
  if (localAuth?.userName) {
    cognitoUser = userPool.getCurrentUser();
    if (cognitoUser === null) {
      cognitoUser = new CognitoUser({
        Username: localAuth?.userName,
        Pool: userPool,
      });
    }
    localAuth.idToken && initConfigCredentials(localAuth.idToken);
  }

  return {
    registerUser,
    signIn,
    changePassword,
    confirmPassword,
    signOut,
    forgotPassword,
    setUserObject,
    getUserAttributes,
    updateUserAttributes,
    deleteUser,
    listUsers,
    processUserAttributes,
    getSession,
    isSessionValid,
  };
}

const CognitoService = cognitoService();
export default CognitoService;
